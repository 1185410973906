<template>
  <section class="head">
    <div class="content">
      <div class="left">
        <a-breadcrumb>
          <a-breadcrumb-item>
            <router-link to="/shop">{{ $t('MN_IntegralMall_MHome') }}</router-link>
            <!-- 积分商城 -->
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ $t('shop.integral_rule') }}</a-breadcrumb-item>
          <!-- 积分兑换规则 -->
        </a-breadcrumb>
      </div>
      <div class="right">
        {{ $t('mine.my_integral') }}：
        <!-- 我的积分： -->
        <span class="integral">{{ myIntegral }}</span>
        <span style="color: #ccc; font-size: 14px; padding: 0 5px">｜</span>
        <router-link to="/shop/order">{{ $t('shop.my_exchange_record') }}</router-link>
        <!-- 我的兑换记录 -->
      </div>
    </div>
  </section>

  <section class="rule">
    <div class="content">
      <div class="tit">{{ $t('shop.integral_rule') }}</div>
      <!-- 积分兑换规则 -->
      <div class="vhtml" v-if="ruleData" v-html="ruleData"></div>
      <a-empty v-else style="padding: 50px 0" />
    </div>
  </section>
</template>

<script>
import { reactive, toRefs } from "vue";
import { giftRule } from "@/api/shop";
export default {
  setup() {
    const state = reactive({
      myIntegral: 0,
      ruleData: "",
    });

    giftRule().then((res) => {
      state.myIntegral = res.data.integral || 0;
      state.ruleData = res.data.shop.ruleIntro || "";
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  .content {
    .mixinWrap();
    height: 65px;
    .mixinFlex(space-between; center);
    .left {
      ::v-deep(.ant-breadcrumb-link) {
        color: #999999;
        a {
          color: #999999;
          &:hover {
            color: @color-theme;
          }
        }
      }
    }
    .right {
      color: #333;
      user-select: none;
      font-size: 16px;
      .mixinFlex(flex-start; center);
      .integral {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #dd0c16;
      }
      a {
        font-size: 14px;
        line-height: 21px;
        color: #333;
        &:hover {
          color: @color-theme;
        }
      }
    }
  }
}

.rule {
  padding-bottom: 68px;
  .content {
    .mixinWrap();
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    .tit {
      font-size: 18px;
      line-height: 18px;
      color: #333;
      font-weight: bold;
      padding-left: 6px;
      margin: 4px 0 10px;
      border-left: 6px solid @color-theme;
    }
  }
}
</style>